// Custom style
// Your custom style to override base style

/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.clients-list {
  margin-top: 0;  // mgupta: was 20px
}

#page-wrapper {
  min-height: calc(100vh - 60px); // mgupta: Because of the fixed top navbar
}
